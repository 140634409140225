/* eslint-disable consistent-return */
import CryptoJS from 'crypto-js';

// Clear localStorage and reload the page to log out the user
export const logoutUser = () => {
  localStorage.clear();
  window.location.reload();
};

// Decrypts AES-encrypted string using the provided key
const decryptAESEncryption = (encryptedString, key) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedString, key);
    const string = bytes.toString(CryptoJS.enc.Utf8);

    const resultObject = JSON.parse(string);

    return resultObject;
  } catch (e) {
    console.log(e.message);
    logoutUser();
  }
};

// Lazy-load app modules
const loadAppModules = () => {
  const modules = JSON.parse(localStorage.getItem('appModules'));

  if (!modules) {
    logoutUser();
  }

  const moduleKey = 'm_a0b9_c8d7';
  const decryptedObject = decryptAESEncryption(modules, moduleKey);
  return decryptedObject;
};

// Lazy-load active session keys
const loadActiveSessionKeys = () => {
  const activeKeys = JSON.parse(localStorage.getItem('activeSessionKeys'));

  if (!activeKeys) {
    logoutUser();
  }

  const permissionKey = 'p_a0b9_c8d7';
  const decryptedObject = decryptAESEncryption(activeKeys, permissionKey);

  return decryptedObject;
};

// Function to get permissions only when accessed
export const getPermissions = () => {
  const activeModules = loadAppModules();
  const activePermissionKeys = loadActiveSessionKeys();

  // Helper function to check permission
  const hasPermission = (key = null) => {
    if (!key) return false;
    return activePermissionKeys.includes(key);
  };

  return {
    ACTION_PLAN: {
      UPDATE: hasPermission(activeModules?.ACTION_PLAN_UPDATE),
      DELETE: hasPermission(activeModules?.ACTION_PLAN_DELETE),
    },
    CLIENT_CONNECT: {
      CREATE: hasPermission(activeModules?.CLIENT_CONNECT_CREATE),
      READ: hasPermission(activeModules?.CLIENT_CONNECT_READ),
      UPDATE: hasPermission(activeModules?.CLIENT_CONNECT_UPDATE),
      DELETE: hasPermission(activeModules?.CLIENT_CONNECT_DELETE),
    },
    INVENTORY_PRODUCT: {
      CREATE: hasPermission(activeModules?.INVENTORY_PRODUCT_CREATE),
      UPDATE: hasPermission(activeModules?.INVENTORY_PRODUCT_UPDATE),
      DELETE: hasPermission(activeModules?.INVENTORY_PRODUCT_DELETE),
    },
    INVENTORY_PRODUCT_TYPE: {
      CREATE: hasPermission(activeModules?.INVENTORY_PRODUCT_TYPE_CREATE),
      UPDATE: hasPermission(activeModules?.INVENTORY_PRODUCT_TYPE_UPDATE),
      DELETE: hasPermission(activeModules?.INVENTORY_PRODUCT_TYPE_DELETE),
    },
    INVENTORY_TRANSACTION: {
      CREATE: hasPermission(activeModules?.INVENTORY_TRANSACTION_CREATE),
      UPDATE: hasPermission(activeModules?.INVENTORY_TRANSACTION_UPDATE),
      DELETE: hasPermission(activeModules?.INVENTORY_TRANSACTION_DELETE),
    },
    SURVEY: {
      CREATE: hasPermission(activeModules?.SURVEY_MODULE_CREATE),
      READ: hasPermission(activeModules?.SURVEY_MODULE_READ),
      UPDATE: hasPermission(activeModules?.SURVEY_MODULE_UPDATE),
      DELETE: hasPermission(activeModules?.SURVEY_MODULE_DELETE),
    },
    KNOWLEDGEBASE: hasPermission(activeModules?.KNOWLEDGEBASE_MODULE),
    CUSTOMER_AUDIT: hasPermission(activeModules?.CUSTOMER_AUDIT_MODULE),

    TASK: {
      CREATE: hasPermission(activeModules?.TASK_MODULE_CREATE),
      UPDATE: hasPermission(activeModules?.TASK_MODULE_UPDATE),
    },
    COMPLAINTS: {
      VIEW_PERSONAL_INFO: hasPermission(activeModules?.VIEW_PERSONAL_INFO),
    },
    DASHBOARD: {
      CUSTOMER: hasPermission(activeModules?.DASHBOARD_CUSTOMER),
      EXPERIENCE: hasPermission(activeModules?.DASHBOARD_EXPERIENCE),
      AUDIT: hasPermission(activeModules?.DASHBOARD_AUDIT),
      SURVEY: hasPermission(activeModules?.DASHBOARD_SURVEY),
      'HELP-DESK': hasPermission(activeModules?.DASHBOARD_HELP_DESK),
    },
  };
};

import { getPermissions } from './permission';

const checkDefaultDahboardPermission = () => {
  const permissions = getPermissions();
  console.log('permissions==', permissions.DASHBOARD);
  const defaultDashboard = localStorage
    .getItem('defaultDashboard')
    .toUpperCase();
  return permissions.DASHBOARD[defaultDashboard];
};

const getFirstTrueDashboard = () => {
  // Convert object to array of entries and find the first entry with a true value
  const permissions = getPermissions();
  const firstTrueDashboard = Object.entries(permissions.DASHBOARD)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .find(
      // eslint-disable-next-line no-unused-vars
      ([key, value]) => value === true
    )?.[0]
    ?.toLocaleLowerCase();

  console.log('firstTrueDashboard', firstTrueDashboard);

  return firstTrueDashboard || '';
};

const checkCompanyDefaultDahboardPermission = () => {
  const permissions = getPermissions();
  console.log('company dashboard permission', permissions.DASHBOARD);
  const defaultDashboard = localStorage
    .getItem('companyDefaultDashboard')
    .toUpperCase();

  return permissions.DASHBOARD[defaultDashboard];
};

export {
  checkDefaultDahboardPermission,
  getFirstTrueDashboard,
  checkCompanyDefaultDahboardPermission,
};

import axios from 'axios';
import { NotificationManager } from 'components/common/react-notifications';

const BaseURL = process.env.REACT_APP_BASE_URL;
const postData = async (url, body, token = null) => {
  const jwsToken = localStorage.getItem('jws_token');

  const response = await fetch(`${BaseURL}/${url}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      //  "Authorization":"Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9hYXJvZ3lhaW5kaWEuaW5cL3RyYWZpa3NvbFwvcHVibGljXC9hcGlcL3YxXC9sb2dpbiIsImlhdCI6MTU5OTEyMzA1MiwiZXhwIjoxNjAxNzE1MDUyLCJuYmYiOjE1OTkxMjMwNTIsImp0aSI6IlJQVmttMHhmcFRzZHpHaGoiLCJzdWIiOjEsInBydiI6Ijg3ZTBhZjFlZjlmZDE1ODEyZmRlYzk3MTUzYTE0ZTBiMDQ3NTQ2YWEifQ.DFp2qevRXpL5DKJNSyrryVAnGD0BWXm-GiMVQeNq5LQ",
      Authorization: token || jwsToken,
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
    },
    //  'content-type': 'multipart/form-data'},
    body: JSON.stringify(body),
  });
  if (response.status === 401) {
    localStorage.clear();
    window.location.reload(false);
  } else if (!(response.status === 200 || response.status === 201)) {
    const { message = 'Something went wrong.' } = await response.json();
    NotificationManager.error(message, 'Error message', 3000, null, null, '');
  }

  try {
    const result1 = await response.json();
    return result1;
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return null; // Return null or handle error accordingly
  }
};

const getData = async (url) => {
  const jwsToken = localStorage.getItem('jws_token');
  const response = await fetch(`${BaseURL}/${url}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: jwsToken,
      'Content-Type': 'application/json; charset=utf-8',
    },
  });

  const result2 = await response.json();

  if (response.status === 401) {
    localStorage.clear();
    window.location.reload(false);
  } else if (response.status !== 200) {
    NotificationManager.error(
      result2?.message ?? 'Something Went Wrong',
      'Error',
      3000,
      null,
      null,
      ''
    );
  }

  return result2;
};

const axiosPostData = async (url, formData) => {
  return axios
    .post(`${BaseURL}/${url}`, formData, {
      headers: {
        Authorization: localStorage.getItem('jws_token'),
        'Content-Type': 'multipart/form-data',
      },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      } else if (err.response.status !== 200) {
        NotificationManager.error(
          err.response.data.message,
          'Error message',
          3000,
          null,
          null,
          ''
        );
      }
    });
};

const requestsInProgress = new Map();
const axiosFetchData = async (url, body, token = null) => {
  const jwsToken = localStorage.getItem('jws_token');

  // Creating an instance of CancelToken
  const source = axios.CancelToken.source();
  const requestKey = `post:${url}`;
  // Function to cancel the request
  if (requestsInProgress.has(requestKey)) {
    requestsInProgress.get(requestKey).cancel('Duplicate request');
  }
  requestsInProgress.set(requestKey, source);

  try {
    const response = await axios.post(`${BaseURL}/${url}`, body, {
      headers: {
        Authorization: token || jwsToken,
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
      },
      cancelToken: source.token, // Assigning the token to the request
    });

    if (response.status === 401) {
      localStorage.clear();
      window.location.reload(false);
    } else if (!(response.status === 200 || response.status === 201)) {
      const { message = 'Something went wrong.' } = response.data;
      NotificationManager.error(message, 'Error message', 3000, null, null, '');
    }

    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message);
    } else {
      console.error('Error:', error);
      // Handle other errors accordingly
    }
    return null; // Return null or handle error accordingly
  } finally {
    requestsInProgress.delete(requestKey);
  }
};

const deleteData = async (url, body, token = null) => {
  const jwsToken = localStorage.getItem('jws_token');

  const response = await fetch(`${BaseURL}/${url}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      Authorization: token || jwsToken,
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  });
  if (response.status === 401) {
    localStorage.clear();
    window.location.reload(false);
  } else if (!(response.status === 200 || response.status === 201)) {
    const { message = 'Something went wrong.' } = await response.json();
    NotificationManager.error(message, 'Error message', 3000, null, null, '');
  }

  try {
    const result1 = await response.json();
    return result1;
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return null; // Return null or handle error accordingly
  }
};

export { postData, getData, axiosPostData, axiosFetchData, deleteData };
